import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "question",
    "sample",
    "answer",
    "solution",
    "sampleHeader",
    "quiz",
    "explanation",
    "submitButton"
  ]

  static values = { answered: Boolean, nextUrl: String, c: Boolean, id: Number }

  toggle(e) {

    if (!this.answeredValue) {
      e.currentTarget.classList.toggle("checked")

      let index = this.solutionTargets.indexOf(e.currentTarget)

      if (e.currentTarget.classList.contains("checked")) {
        this.answerTargets[index].classList.add("text-purple-600")
        this.answerTargets[index].classList.remove("text-transparent")
      } else {
        this.answerTargets[index].classList.add("text-transparent")
        this.answerTargets[index].classList.remove("text-purple-600")
      }

    }

  }

  answer(e) {

    if (this.answeredValue) {

      if (this.hasNextUrlValue) {
        fetch(this.nextUrlValue).then(function(response) {
          return response.text()
        }).then(function(response) {
          document.getElementById("quiz-target").innerHTML = response
        })        
      }

      return
    }

    this.answeredValue = true
    let all_correct = true

    if (this.hasSampleHeaderTarget) {
      this.sampleHeaderTarget.classList.remove('text-transparent')
      this.sampleHeaderTarget.classList.add('text-purple-600')
    }

    if (this.hasQuizTarget) {
      this.quizTarget.classList.add('answered')
    } else {
      console.log("NO QUIZ TARGET")
    }

    this.solutionTargets.forEach((solution, index) => {
      this.sampleTargets[index].classList.remove('invisible')

      if (solution.dataset.x === 'c' && solution.classList.contains('checked')) {
        solution.classList.add('true-positive')
        this.sampleTargets[index].classList.add("text-purple-600")
        this.sampleTargets[index].classList.remove("text-transparent")
      } else if (solution.dataset.x !== 'c' && !solution.classList.contains('checked')) {
        solution.classList.add('true-negative')
      } else if (solution.dataset.x !== 'c' && solution.classList.contains('checked')) {
        solution.classList.add('false-positive')
        all_correct = false
      } else {
        solution.classList.add('false-positive')
        this.sampleTargets[index].classList.add("text-purple-600")
        this.sampleTargets[index].classList.remove("text-transparent")
        all_correct = false
      }

    })

    if (this.hasExplanationTarget) {
      this.explanationTarget.innerHTML = window.data.explanations[this.idValue].explanation
    }

    if (all_correct) {
      this.cValue = true

      if (this.hasQuizTarget) {
        this.quizTarget.classList.add('correct')
      }
    }

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.innerHTML = "Weiter"
    }

    window.dispatchEvent(new CustomEvent("single-answered"))
  }

}