import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 
    "question",
    "submitButton",
    "previousButton",
    "nextButton",
    "notSeenButton",
    "understoodButton",
    "notSeenLabel",
    "understoodLabel",
    "testResultPercentage",
    "testResultPassed",
    "directUrlButton",
    "lessonStatus"
  ]
  
  static values = { 
    currentLessonUrl: String,
    isCompleted: Boolean,
    currentLessonIndex: Number,
    lessonIdToken: String
  }

  connect() {
    ScormProcessInitialize()
    let completionStatus = ScormProcessGetValue("cmi.completion_status", true);

    if (completionStatus == "unknown") {
      ScormProcessSetValue("cmi.completion_status", "incomplete");
    }

    this.checkLessonCompletionStatus()

    if (this.hasTestResultPercentageTarget) {
      this.testResultPercentageTarget.innerHTML = `${Math.round(ScormProcessGetValue("cmi.score.scaled") * 100)} %`
    }

    if (this.hasTestResultPassedTarget) {
      
      if (ScormProcessGetValue("cmi.success_status") === "passed") {
        this.testResultPassedTarget.innerHTML = "bestanden"
      } else if (ScormProcessGetValue("cmi.success_status") === "failed") {
        this.testResultPassedTarget.innerHTML = "nicht bestanden"
      }

    }
  }

  currentIndex() {
    return this.currentLessonIndexValue
  }

  next(e) {
    e.preventDefault()

    if (e.currentTarget.classList.contains("disabled")) {
      return
    }

    let nextIndex = this.currentIndex() + 1
    
    if (nextIndex < window.data["lesson_urls"].length) {
      window.location = `../${window.data["lesson_urls"][nextIndex].url}`
    }

  }

  previous(e) {
    e.preventDefault()

    if (e.currentTarget.classList.contains("disabled")) {
      return
    }

    let previousIndex = this.currentIndex() - 1
    
    if (previousIndex >= 0) {
      window.location = `../${window.data["lesson_urls"][previousIndex].url}`
    }

  }

  checkLessonCompletionStatus() {
    let completionStatus = ScormProcessGetValue("cmi.objectives." + this.lessonIdTokenValue + ".completion_status", false)

    if (completionStatus == "complete") {
      this.isCompletedValue = true
    } else {
      this.isCompletedValue = false
    }

  }

  completionChanged(e) {

    if (e.target === this.understoodButtonTarget) {
      ScormProcessSetValue("cmi.objectives." + this.lessonIdTokenValue + ".completion_status", "complete")
    } else if (e.target === this.notSeenButtonTarget) {
      ScormProcessSetValue("cmi.objectives." + this.lessonIdTokenValue + ".completion_status", "incomplete")
    }
    
    this.checkLessonCompletionStatus()
  }

  isCompletedValueChanged() {

    console.log("current index", this.currentIndex(), window.data["lesson_urls"][this.currentIndex()].can_be_completed, window.data["lesson_urls"][this.currentIndex()].status_can_be_set)

    if (this.currentIndex() === 0 || this.currentIndex() === window.data["lesson_urls"].length - 1) {
      this.previousButtonTarget.classList.add("disabled")
    }

    if (window.data["lesson_urls"][this.currentIndex()].can_be_completed === true) {
      if (this.isCompletedValue) {
        this.nextButtonTarget.classList.remove("disabled")
        this.understoodButtonTarget.checked = true
      } else {
        this.nextButtonTarget.classList.add("disabled")
        this.notSeenButtonTarget.checked = true
      }

    } 

    if (window.data["lesson_urls"][this.currentIndex()].status_can_be_set !== true) {
      this.notSeenButtonTarget.checked = false
      this.understoodButtonTarget.checked = false
      this.notSeenLabelTarget.classList.add("disabled")
      this.understoodLabelTarget.classList.add("disabled")
      this.nextButtonTarget.classList.remove("disabled")
    }

    if (this.currentIndex() === window.data["lesson_urls"].length - 1) {
      this.nextButtonTarget.classList.add("disabled")
    }

  }

  singleAnswered() {
    let all = true
    let correct = 0
    let wrong = 0

    this.questionTargets.forEach((question) => {

      if (question.dataset.questionAnsweredValue !== "true") {
        all = false
      }

      if (question.dataset.questionCValue === "true") {
        correct++
      } else {
        wrong++
      }

    })

    if (all) {
      ScormProcessSetValue("cmi.score.raw", (correct / (correct + wrong) * 100));
      ScormProcessSetValue("cmi.score.min", "0");
      ScormProcessSetValue("cmi.score.max", "100");
      ScormProcessSetValue("cmi.score.scaled", correct / (correct + wrong));
      
      if (Math.round(correct / (correct + wrong) * 100) >= 50){
        ScormProcessSetValue("cmi.success_status", "passed");
      } else {
        ScormProcessSetValue("cmi.success_status", "failed");
      }

      ScormProcessSetValue("cmi.completion_status", "completed");
    }
  }

  submitTest() {
    window.dispatchEvent(new CustomEvent('answer'))
    this.submitButtonTarget.classList.add("hidden")
    window.scrollTo(0, 0)
  }

  directLinkClick(e) {
    console.log("direct: ", e.target.dataset.url)
    window.location = `../${e.target.dataset.url}`
  }

}